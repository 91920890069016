/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FeatureFlags = {
  properties: {
    CONTRACT_MODULE: {
      type: 'boolean',
    },
    CONTRACT_REVIEWER: {
      type: 'boolean',
    },
    DISABLE_RATE_LIMIT: {
      type: 'boolean',
    },
    ENABLE_SERPRO_NO_EI_VALIDATION: {
      type: 'boolean',
    },
    ENABLE_SERPRO_NO_MEI_VALIDATION: {
      type: 'boolean',
    },
    ENABLE_COMPANY_SHALLOW_NAME_MATCH: {
      type: 'boolean',
    },
    ENABLE_LEAD_COLLECTION: {
      type: 'boolean',
    },
    ENABLE_LEAD_EMAIL: {
      type: 'boolean',
    },
    ENABLE_NEOWAY_VALIDATION: {
      type: 'boolean',
    },
    ENABLE_NEOWAY_BACKOFFICE: {
      type: 'boolean',
    },
    DISABLE_BP_INCLUDE: {
      type: 'boolean',
    },
    DISABLE_BANK_ACCOUNT_VALIDATION: {
      type: 'boolean',
    },
    DISABLE_EMAIL_SENDING: {
      type: 'boolean',
    },
    DISABLE_SERPRO_CPF_STATUS_CHECK: {
      type: 'boolean',
    },
    DISABLE_DOMAIN_PROVIDER_VALIDATION: {
      type: 'boolean',
    },
    ENABLE_USER_PROVIDER_OVERRIDE: {
      type: 'boolean',
    },
    ENABLE_RETURN_TO_GTA: {
      type: 'boolean',
    },
  },
} as const;
